.adminSettingsPage-desktopView {
  display: block;
  margin: auto;
  margin-top: 20rem;
  border: #ffffff;
  box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.5);
  border-radius: 1rem;
  width: 50vw;
  /* padding: 1rem; */
}
.adminBar-desktopView {
  background: #ffffff;
  box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 75px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 2rem;
  /* line-height: 39px; */
  padding: 1rem;
  padding-left: 5rem;
  margin-bottom: 3rem;
  /* border-radius: 2rem; */
  /* margin-top: 4rem; */
}
.adminSettingsContainer-desktopView {
  margin: 1rem;
  /* padding-left: 3rem; */
}
.modifyPricesHeader-desktopView {
  font-family: "Manrope" !important;
  font-style: normal !important;
  font-weight: bolder;
  font-size: 24px;
  line-height: 117.9%;
}

.modifyPrices-desktopView {
  background: #b5d3ff;
  border-radius: 10px;
  padding: 1rem;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
.modifyPrices-desktopView p {
  font-size: 20px;
}
.modifyPriceInput-desktopView {
  margin: 0.2rem;
  border-radius: 0.2rem;
  outline: none;
  box-shadow: none;
  border: none;
}
input:focus {
  outline: none;
  border: none;
}
.modifyPriceInputContainer-desktopView {
  background-color: #154b89;
  padding: 2px;
  border-radius: 0.4rem;
  width: 100%;
  display: flex;
}
.modifyPriceInputContainer-desktopView button {
  background-color: #154b89;
  color: #ffffff;
  border: none;
  text-align: center;
  width: 100%;
}

.Plan-desktopView {
  display: flex;
}
.planHeader-desktopView {
  margin-right: 5%;
  width: 260px;
}
.planHeader-desktopView p {
  margin: 0;
}
.planLabel-desktopView {
  font-family: "Inter" !important;
  font-weight: bold;
  font-size: 1rem;
}
.currentPriceLabel-desktopView {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 17px;
  letter-spacing: -0.05em;

  color: #154b89;
}
.modifyPricesContainer-desktopView {
  margin-bottom: 3rem;
}
.customiseUserPricesHeader-desktopView {
  font-family: "Manrope" !important;
  font-style: normal !important;
  font-weight: bolder;
  font-size: 24px;
  line-height: 117.9%;
}
.customiseUserPrices-desktopView {
  margin-top: 1rem;
  background: #b5d3ff;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}
#mostRecurringUsersHeader-desktopView {
  font-family: "Inter" !important;
  font-weight: bold;
  font-size: 1.5rem;
}
.mostRecurringUsers-desktopView {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
}
.mostRecurringUser-desktopView {
  background: #ffffff;
  border-radius: 10px;
  /* padding: 1rem; */
  text-align: center;
}
.mostRecurringUser-desktopView span {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 110.02%;

  height: 50px;
  color: #000000;
  margin-bottom: 0 !important;
}

.customiseUserPrices-input-container {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}
.inputUser-desktopView input {
  width: 90%;
  /* padding: 0.5%; */
  /* padding: auto; */
}
.inputUser-desktopView {
  width: 50%;
}
.customiseUserPricesInput-desktopView {
  background-color: #154b89;
  padding: 2px;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  /* width: 100%; */
  /* justify-content: space-between; */
}
.customiseUserPricesInput-desktopView button {
  background: transparent;
  border: none;
  color: white;
  /* width: 25%; */
  text-align: center;
  /* width: 50%; */
}
.mostRecurringUser-desktopView {
  margin: 4px;
  background-color: #efefef;
  border-radius: 4px;
  overflow: auto;
  float: left;
}

.mostRecurringUser-desktopView:hover {
  background: #528aca;
  transition-delay: 30ms;
  color: white !important;
}

.mostRecurringUser-desktopView {
  display: block;
  width: 100% !important;
  height: 100%;
  text-align: center;
}

.mostRecurringUser-desktopView span {
  text-align: center;
  padding: 0.625rem;
  display: block;
  width: 100% !important;
  height: 100%;
}

.mostRecurringUser-desktopView input {
  position: absolute;
  top: -20px;
  width: 100%;
  /* height: 100%; */
}

.mostRecurringUser-desktopView input:checked + span {
  background-color: #154b89;
  color: #fff;
}
.checked {
  background-color: #154b89;
  color: #fff;
}
.ad-btn:hover{
  background-color:#238d56 !important;
  color: #fff;
}


@media (max-width: 880px) {
  .modifyPrices-desktopView {
    background: #b5d3ff;
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 1rem;
    gap: 1rem;
  }
  .Plan-desktopView {
    flex-wrap: wrap;
  }
  .customiseUserPrices-input-container {
    flex-direction: column;
    align-items: center;
  }
  .customiseUserPrices-desktopView {
    width: 100%;
  }
}
@media (max-width: 1670px) {
  .modifyPrices-desktopView {
    background: #b5d3ff;
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    margin-top: 1rem;
    gap: 1rem;
  }
}
.parent-container-desktopView {
  overflow: scroll;
  height: 90vh;
}
.user-requestDesktopView {
  background-color: #b5d3ff;
  border-radius: 10px;
  padding: 15px;
}
.user-requestDesktopView p {
  font-size: 20px !important;
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 700 !important;
}
.user-DesktopView p {
  margin-top: 10px;
  font-size: 24px;
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 500;
}
.userRequests {
  display: flex !important;
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  justify-content: space-evenly;
  height: 70px;
  margin-bottom: 10px !important;
  padding-top: 0px !important;
}
.userRequests p {
  font-size: 14px;
  font-weight: 800;
}
.userRequests button {
  border-radius: 10px;
  color: white;
  background-color: #154b89;
  border: none;
  padding: 7px;
  width: 20%;
}
.div-userrequest {
  margin-left: 2px !important;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.parent-container-desktopView::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.parent-container-desktopView {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.userRequests-input {
  width: 70%;
  margin: 2px;
  border: none;
}
.requestpopup {
  position: absolute;
  bottom: 10%;
  left: 55%;
}
.requestpopup-style {
  width: 240px;
  height: 70px;
  /* display: flex;
  align-items: center;
  justify-content: space-evenly; */
  color: rgb(0, 0, 0);
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  border-radius: 20px;
  background-color: #528aca49;
}
.requestpopup-style p {
  display: flex;
  /* margin-top: 5%; */
  align-items: center;
  justify-content: center;
}
.requestpopup-style img {
  margin-top: 2%;
  width: 15%;
}

.custom-tf {
  border-radius: 8px;
  background: #ffffff;
  /* border: none; */
}