logofont {
  overflow: hidden;
  font-family: "Inter";
  font-style: bold;
  font-size: 32px;
  color: #2e72d2;
}

.logo-wrapper {
  display: block;
  position: relative;
}

.hd-logo {
  padding: 10px 0;
  display: flex;
  flex-direction: row;
}

.img-logo img {
  width: 50px !important;
  margin: 0;
  margin-top: 0 !important;
}
.img-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 10px;
}

.text-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.text-logo h2 {
  font-size: 32px;
  line-height: 39px;
  font-weight: 1000;
  margin: 0;
}

.home-link {
  display: inherit;
  color: inherit;
  text-decoration: none;
}

.home-link:hover {
  color: inherit;
}

/* .text-logo h2,
.img-logo {
  display: none;
} */
@media only screen and (max-width: 1500px) {
  .img-logo img {
    width: 30px !important;
    margin: 0px !important;
  }
  .text-logo h2 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 1000;
  }
  .img-logo {
    height: 60px;
  }
}

@media only screen and (max-width: 1100px) {
  .img-logo img {
    width: 20px !important;
    margin: 0px !important;
  }
  .text-logo h2 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 1000;
  }
  .img-logo {
    height: 60px;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 601px) {
  .img-logo img {
    width: 40px !important;
    margin: 0px !important;
  }
  .img-logo {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
  }
  .text-logo h2 {
    font-size: 28px;
    line-height: 32px;
    font-weight: 1000;
  }
  .img-logo {
    height: 60px;
  }
}

@media only screen and (max-width: 600px) {
  .img-logo img {
    width: 40px !important;
    margin-top: 0 !important;
  }
  .img-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .text-logo h2 {
    font-size: 28px;
    line-height: 39px;
    font-weight: 1000;
  }
}

@media only screen and (max-width: 340px) {
  .img-logo img {
    width: 30px !important;
    margin-top: 0 !important;
  }
  .text-logo h2 {
    font-size: 24px;
  }
}
