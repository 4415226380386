.footer {
  padding: 0 20px;
}

.footer-logo {
  height: 5vh;
  max-height: 50px;
  margin: 0;
}

.left-side-content {
  text-align: start;
}

.right-side-content {
  text-align: end;
}
