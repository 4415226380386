* {
  font-family: "Manrope" !important;
  font-style: normal !important;
}

.closeacc {
  background: rgba(181, 211, 255, 0.9) !important;
  margin: 15px;
}

.openacc {
  overflow-y: auto;
  max-height: 65vh;
  background: rgba(140, 185, 251, 0.9);
  padding-top: 15px;
  padding-bottom: 15px;
}

.indivcard {
  background: #ffffff;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  margin: 10px;
  white-space: pre-wrap;
}

.carousel .headings {
  font-size: 16px;
}

.btwcards {
  height: 10px;
  background: rgba(140, 185, 251, 0);
}

.destyle {
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 380px;
  text-align: center;
}

.descard {
  height: 175px;
  border-radius: 5px;
  background: #b5d3ff;
}

.cardestyle {
  padding-left: 60px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 350px;
  height: 140px;
  overflow: hidden;
  text-align: center;
}

.cardescard {
  height: 175px;
  border-radius: 5px;
  background: #b5d3ff;
}

.instruction-carousel-history {
  display: block !important;
  border-radius: 5px;
  background: #b5d3ff !important;
  height: 100%;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5)) !important;
  color: #000 !important;
  padding: 10px;
  font-size: 18px;
  font-family: auto;
}

.instruction-title {
  background: #b5d3ff;
  height: 38px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  /* filter: drop-shadow(5px 5px 20px rgba(0, 0, 0, 0.5)) !important; */
}
.instruction-desc {
  filter: drop-shadow(5px 5px 20px rgba(0, 0, 0, 0.5)) !important;
  background: #b5d3ff;
  border-radius: 5px;
  height: 163px;
  margin-top: -2%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.n-txt {
  text-align: center;
  font-weight: 600;
  font-size: 28px;
  color: #02111f;
}

.card-header-section-history {
  display: flex;
}

.card-subheader-history {
  min-width: 100px;
}

.pagination-wrap {
  display: flex;
  justify-content: center;
}

@media (max-width: 370px) {
  .card-header-section-history {
    display: block;
  }

  .card-subheader-history {
    width: auto;
  }
}
