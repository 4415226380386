* {
  font-family: "Manrope";
  font-style: normal;
}

.mobile-footer-icon {
  height: 100%;
  margin: 0;
}

@media only screen and (max-width: 600px) and (min-width: 200px) {
  .sidebar-mobile-wrapper {
    overflow: hidden;
    height: 100%;
    padding: 20px 10px;
  }

  /* @supports (scrollbar-gutter: stable) {
    .sidebar-mobile-wrapper {
      overflow-y: auto;
      scrollbar-gutter: stable;
    }
  } */

  .sidebar-mobile {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
  }

  .sidebar-mobile::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  .sidebar-mobile .accordion {
    width: 100%;
    font-size: 20px !important;
  }
  .sidebar-mobile-home-btn {
    text-align: center;
    text-decoration: none;
    border: 1px solid #000;
    border-radius: 8px;
    background: #b5d3ff;
    color: #000 !important;
    font-weight: 600 !important;
    padding: 8px;
    width: 100%;
    font-size: 20px !important;
  }
  .accordion-button {
    font-size: 20px !important;
  }
  .accordion-header button {
    font-size: 20px !important;
  }
  .category-link {
    font-size: 20px !important;
  }
  ul {
    list-style: none;
  }
  .footer {
    background-color: rgb(181 211 255);
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: 800;
    margin-left: -10%;
  }
  .mobile-footer-text {
    font-size: 9px;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 601px) {
  .sidebar-mobile-wrapper {
    overflow: hidden;
    padding: 20px 10px;
    height: 100%;
  }

  .sidebar-mobile {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
  }

  .sidebar-mobile::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  .sidebar-mobile .accordion {
    width: 100%;
    font-size: 20px !important;
  }

  .sidebar-mobile-home-btn {
    text-align: center;
    text-decoration: none;
    border: 1px solid #000;
    border-radius: 8px;
    background: #b5d3ff;
    color: #000 !important;
    font-weight: 600 !important;
    padding: 8px;
    width: 100%;
    font-size: 20px !important;
  }
  .accordion-button {
    font-size: 20px !important;
  }
  .accordion-header button {
    font-size: 20px !important;
  }
  .category-link {
    font-size: 20px !important;
  }
  ul {
    list-style: none;
  }
  .footer {
    background-color: rgb(181 211 255);
    padding: 10px;
    position: relative;
    font-weight: 800;
    margin-left: -5%;
  }
  .mobile-footer-text {
    font-size: 16px;
  }
}
