.blue-clr {
  color: #2e72d2;
}

.bg-ac {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(../../assets/images/authentication-background.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding: 20px;
}

.cr-wrapper {
  width: 614px !important;
  max-width: fit-content;
  padding: 1.5em 2.5em;
  background: #ffffff;
  box-shadow: 0px 0px 50px 3px rgb(0 0 0 / 40%);
  border-radius: 20px;
}

.hr-or {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  color: rgb(0 0 0 / 46%);
}

.hr-or:before,
.hr-or:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}

.hr-or:before {
  margin-right: 10px;
}

.hr-or:after {
  margin-left: 10px;
}

.w-100 {
  width: 100%;
}

.sign-btn {
  background: #2e72d2 !important;
  width: 100%;
}

.bg-ac .logo {
  width: 100%;
  height: auto;
}

.bg-ac .icons {
  width: 25px;
  height: auto;
}

.text-container {
  display: flex;
  position: relative;
  flex-direction: column;
}

.text-container strong {
  display: block;
  text-align: center;
}

.text-container span {
  display: block;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.text-container h1 strong {
  display: block;
  text-align: center;
  font-size: 36px;
}

@media (max-width: 1000px) {
  .cr-wrapper {
    padding: 0.5em 2em;
    border-radius: 10px;
  }

  .text-container h1 strong {
    font-size: 32px;
    line-height: 44px;
  }

  .text-container span {
    font-size: 20px;
    line-height: 27px;
  }
}

@media (max-width: 600px) {
  .cr-wrapper {
    padding: 1em 1em;
    border-radius: 10px;
  }

  .text-container h1 strong {
    font-size: 20px;
    line-height: 27px;
  }

  .text-container span {
    font-size: 14px;
    line-height: 19px;
  }
}
