.comparison-percent {
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
}

.gradient-background-green {
  background: radial-gradient(circle at bottom right, #03850080 0, #cbffca 70%);
  border-radius: 15px;
  width: 100%;
  height: 100%;
  padding: 27px 32px;
}

.gradient-background-red {
  background: radial-gradient(circle at bottom right, #b4000080 0, #ffcccc 70%);
  border-radius: 15px;
  width: 100%;
  height: 100%;
  padding: 27px 32px;
}
