.analytics-dashboard {
  background-color: #b5d3ffb2;
  width: 100vw;
  height: 100vh;
  padding: 50px;
  overflow: auto;
}

.statistics-card {
  background-color: #ffffffb2;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  padding: 27px 32px;
}

.statistics-title {
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  margin: 0px 0px 5px 0px;
}

.normal-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
}

.large-text-blue {
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #154b89;
  margin: 0;
}

.medium-text-blue {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #154b89;
  margin: 0;
}

.small-text-blue {
  font-weight: 600;
  font-size: 18px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: #154b89;
  margin: 0;
}

.medium-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
}

.view-all-btn {
  background: #154b89;
  border-radius: 5px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: white;
  border: none;
}

.stacked-cards {
  height: 100%;
}

.list-section {
  margin-top: 10px !important;
}

.flag-img {
  height: 32px;
}

.table {
  background-color: #eff6ff !important;
  box-shadow: none !important;
}

.table-cell {
  border-color: rgba(0, 0, 0, 0.3) !important;
}

.text-margin {
  margin: 0 10px;
}

.horizontal-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: none;
}
