@media only screen and (max-width: 900px) {
  .robot-img-homepage {
    display: none !important;
  }
  .title-homepage {
    display: none !important;
  }
  .logo-homepage {
    width: 180% !important;
    margin-left: -10% !important;
    margin-top: 30% !important;
  }
  .subtitle-homepage {
    display: none !important;
  }
  .instruction-skip-btn-homepage {
    display: flex;
  }
  .skipbutton {
    /* display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; */
    margin-top: 16% !important;
    right: 20% !important;
  }

  .content-homepage {
    background: rgba(255, 255, 255, 0.65);
    box-shadow: 0px 4px 35px 10px rgba(0, 0, 0, 0.25);
    padding: 20px;
    border-radius: 10px;
  }
  .content-homepage p {
    text-align: center;
    font-weight: 800 !important;

    border-radius: 10px;
    padding: 10px;
    transition: ease 0.5s;
  }
  .content-homepage p[aria-expanded="true"] {
    transform: rotateY(180deg);
    transition: ease 0.5s;
  }
  .bg-image-mobile {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(46, 46, 185, 0.5);
    background-image: url("../../assets/27.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .instructionMobile {
    transform: rotateY(180deg);
  }
  .infobutton {
    width: 100% !important;
    font-size: 18px;
    border-radius: 5px;
    margin-top: 0px !important;
  }

  .skipbutton {
    width: 100% !important;
    font-size: 18px;
    border-radius: 5px;
    margin-top: 0% !important;
  }

  .welcome {
    display: none;
  }

  .subheader {
    display: none;
  }
}

.skipbutton {
  right: 10%;
}
.homepage {
  display: flex;
  text-align: center;
  align-items: center;
  padding: 20px;
  height: 100%;
}

.robot-img {
  width: 100%;
  height: 100%;
}

.homepage-info-section {
  margin: 0 auto;
  width: 90%;
}

.robot-logo {
  width: 80%;
  height: 80%;
  margin-bottom: 1em;
}

.welcome {
  font-size: 30px;
  font-weight: bold;
}

.subheader {
  font-size: 18px;
}

.description-mobile {
  display: none;
}

.description {
  display: block;
  font-size: 18px;
}

/* @media (max-width: 900px) {
  .robot-img {
    width: 50%;
  }

  .robot-logo {
    margin-top: 0;
  }
} */

@media (max-width: 900px) {
  .description-mobile {
    display: block;
    font-size: 18px;
  }

  .description {
    display: none;
  }
}
