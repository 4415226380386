.modal-background {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: rgba(189, 189, 189, 0.8);
  padding: 10px;
  z-index: -1;
}

.modal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.modal-container {
  margin: 0 auto;
  background-color: #ffffff;
  width: 70%;
  height: 500px;
  border-radius: 10px;
  min-width: fit-content;
  min-height: fit-content;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  background-color: #99bdf4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}
.modal-header button {
  background-color: transparent;
  border: none;
}
.modal-header h2 {
  margin: 0.5rem;
  font-family: Manrope;
  font-weight: 800;

  color: #154b89;
}
.card-container1 {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.card-wrapper1 {
  width: 320px;
  height: 250px;
  background-color: #99bdf4;
  border-radius: 20px;
  margin: 15px;
  margin-top: 4rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}
.card-wrapper1 h4 {
  width: 320px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0px;
  top: 0px;
  color: #154b89;
  font-family: Manrope;
  font-weight: 600;
  background-color: #b5d3ff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}
.card-wrapper1 p {
  font-size: 20px;
  line-height: normal;
  margin: 2rem;
  font-family: Manrope;
  font-weight: 600;
  text-align: center;
}

.end-modalcontent {
  text-align: center;
  color: #154b89;
  font-family: Manrope;
  margin: 2rem;
}

.end-modalcontent h3 {
  font-weight: 800;
  font-size: 28px;
}
.end-modalcontent p {
  font-size: 18px;
  line-height: normal;
}
.modal {
  display: block !important;
}
.close-button {
  border: none;
  background-color: inherit;
}
.close-icon {
  scale: 1.5;
}
.close-icon:hover {
  filter: opacity(0.5);
}
.instruction-modal-link {
  margin-top: 15px;
}

@media (max-width: 1200px) {
  .card-wrapper1 {
    width: 280px;
    margin: 13px;
    margin-top: 4rem;
  }

  .card-wrapper1 h4 {
    width: 280px;
  }
}

@media (max-width: 900px) {
  .card-wrapper1 {
    width: 250px;
    margin: 10px;
    margin-top: 4rem;
  }

  .card-wrapper1 h4 {
    width: 250px;
  }
}

@media only screen and (max-width: 900px) {
  .modal {
    display: none !important;
  }
}
