.subject-list {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 5px !important;
}

.subject {
  position: absolute;
  display: flex;
  align-items: center;
  height: 32px;
  margin: 10px 0 !important;
}

.data-bar {
  width: 100%;
  height: 32px;
  background-color: #cbe0ff;
  border-radius: 5px;
}

.data-bar-progress {
  display: flex;
  align-items: center;
  height: 100%;
  color: white;
  padding-left: 20px;
  background-color: #154b89;
  border-radius: 5px;
}
.statistics-title_ {
  font-weight: 900;
  width: max-content;
}
@media (max-width: 800px) {
  .text-center {
    font-weight: 700;
  }
}
