@media only screen and (max-width: 1000px) {
  .instruction-carousel1 {
    transform: rotateY(180deg) !important;
  }
  .instruction-carousel1 {
    display: block !important;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
  }
  .instruction-title-mobile {
    background: #b5d3ff;
    height: 38px;
    min-height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    font-weight: 600;
    border-radius: 10px 10px 0px 0px;
    margin: 0;
    padding-bottom: 5px;
  }
  .instruction-desc-mobile {
    background: #99bdf4;
    border-radius: 0px 0px 10px 10px;
    height: 163px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 600;
    padding: 0 40px;
    margin: 0;
  }
}

.instruction-carousel1 {
  display: none;
}
