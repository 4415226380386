.img-container {
    display: block;
    padding: 10px;
    box-shadow: 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
    margin: 30px 40px 40px 0;    
    width:auto !important
}

.img-block {
    display: flex;
    flex-direction: column;    
}

.img-block > img {    
    height: auto;
    margin:10px ;
}

.gn-btn {
    background: #154b89 !important;
    color: #fff !important;
    font-weight: 600 !important;
    margin: 5px !important;
    border-radius: 4px;
}

.gn-btn:hover {
    background: #1e60a1a8 !important;
    color: #fff !important;
    border-radius: 4px;
}

.category-title-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 800;
    margin: 0;
    align-items: center;
    border-radius: 0;
    border-radius: 4px;
}

.img-wrapper {
    display: flex;
    flex-direction: column;    
    justify-content: center;
}

.img-wrapper>img {
    height: 100%;
    width: 500px;
    align-self: center;
    border:1px solid #154b89;
    border-radius: 4px;
    margin-top: 50px
}

span.img-op{
    background: black;
    color: #fff;
    padding: 5PX;
    border-radius: 4PX;
    text-transform: capitalize;
}

.img-op-container {
    height: 370px;
    overflow-y: auto;
    color: #fff !important;
    border-radius: 8px;
}

.tbl{
    background: #c1d8ec;
    margin: 10px;
}