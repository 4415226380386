.authentication-page {
  background-image: url("../../assets/images/authentication-background.png");
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.authentication-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 0px 50px 3px rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  width: 614px !important;
  padding: 1.5em 3em;
  text-align: center;
  /* min-height: 627px !important; */
}

.authentication-grid {
  align-content: flex-start;
}

.app-logo {
  width: 100%;
  height: 100%;
  margin: 0;
  height: 100%;
}

.inside {
  display: block;
}

.outside {
  display: none;
}

.card-header {
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  margin: 0;
}

.card-subheader {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  margin: 0;
  margin-bottom: 10px;
}

.google-btn {
  display: block;
  background: #2e72d2;
  border-radius: 10px;
  height: 46px;
  padding: 5px;
  width: 100%;
  border: none;
}

.google-btn:hover {
  background: #1b4784;
  cursor: pointer;
}

.facebook-btn {
  display: block;
  background: #154b89;
  border-radius: 10px;
  height: 46px;
  padding: 5px;
  width: 100%;
  border: none;
}

.facebook-btn:hover {
  background: #0b2a4d;
  cursor: pointer;
}

.apple-btn {
  display: block;
  background: #000000;
  border-radius: 10px;
  height: 46px;
  padding: 5px;
  width: 100%;
  border: none;
}

.apple-btn:hover {
  background: rgb(58, 58, 58);
  cursor: pointer;
}

.google-btn img,
.facebook-btn img,
.apple-btn img {
  height: 100%;
}

.line {
  width: 100%;
  height: 0px;
  border: 1.5px solid rgba(0, 0, 0, 0.25);
  margin: 0;
}

.divider-text {
  color: rgba(0, 0, 0, 0.3);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin: 10px 0;
}

.incorrect-input {
  border-color: red !important;
  border-radius: 4px !important;
  width: 100%;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.form-input {
  margin: 5px 0 !important;
}

.forgot-password-wrapper {
  display: flex;
}

.forgot-password-btn {
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.35);
  text-align: left;
  margin: 0;
}

.forgot-password-btn:hover {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: underline;
}

.login-help-wrapper {
  display: flex;
  width: 100%;
}
.login-help-btn {
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.35);
  margin: 0;
  margin-left: auto;
}

.login-help-btn:hover {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: underline;
}

.submit-btn {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  width: 100%;
  border: none;
  background: #6fa9ff;
  border-radius: 10px;
  height: 1.5em;
  margin-top: 0.8em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.submit-btn:hover:enabled {
  background: #406397;
}

.submit-btn:disabled {
  color: rgba(0, 0, 0, 0.2);
  background: #b5d3ff;
  cursor: not-allowed;
}

.submit-btn-2 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  width: 100%;
  border: none;
  background: rgba(21, 75, 137, 1);
  border-radius: 10px;
  height: 1.5em;
  margin-top: 0.8em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.submit-btn-2:hover:enabled {
  background: rgb(13, 49, 91);
}

.submit-btn:disabled,
.submit-btn-2:disabled {
  color: rgba(0, 0, 0, 0.2);
  background: #b5d3ff;
  cursor: not-allowed;
}

.submit-link {
  display: flex;
  text-decoration: none;
  width: 100%;
  height: 100%;
  justify-content: center;
  border: none;
  background: none;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.submit-link p {
  margin: auto 0;
}

.submit-text {
  margin: auto 0;
}

.submit-link:hover,
.submit-text:hover {
  color: inherit;
}

.secondary-btn {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #154b89;
  width: 100%;
  border: 6px solid #154b89;
  border-radius: 10px;
  background: #ffffff;
  height: 1.5em;
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.secondary-btn:hover {
  background: #154b89;
  color: #ffffff;
}

.secondary-btn:disabled {
  background: rgba(181, 211, 255, 1);
  color: rgba(0, 0, 0, 0.2);
  border: none;
  cursor: not-allowed;
}

.text-link {
  color: rgba(46, 114, 210, 1);
  text-decoration: none;
}

.form-btn {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  width: 100%;
  height: 40px;
  border: none;
  background: rgba(21, 75, 137, 1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-btn:disabled {
  color: rgba(0, 0, 0, 0.2);
  background: #b5d3ff;
  cursor: not-allowed;
}

.form-btn:hover:enabled {
  background: rgb(13, 49, 91);
}

.form-btn:disabled {
  color: rgba(0, 0, 0, 0.2);
  background: #b5d3ff;
}

.login-return {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  text-align: center;
}

.small-text {
  display: block;
  font-size: small;
  font-weight: bolder;
  letter-spacing: 0.05em;
  margin: 0;
}

.text-button {
  border: none;
  background-color: transparent;
  padding: 0px;
}

.text-button:hover {
  filter: brightness(0.9);
}

@media (max-width: 1000px) {
  .authentication-page {
    background-image: url("../../assets/images/authentication-background-tablet.png");
    padding: 20px;
  }

  .authentication-card {
    border-radius: 10px;
    /* min-height: 447px !important; */
    width: 568px !important;
    max-width: fit-content;
    /* padding: 0.5em 2em; */
    box-shadow: 0px 4px 35px 10px rgba(0, 0, 0, 0.25);
  }

  .app-logo {
    display: flex;
    height: fit-content;
    max-width: 568px;
    margin: 20px auto;
  }

  .inside {
    display: none;
  }

  .outside {
    display: block;
  }

  .card-header {
    font-size: 32px !important;
    line-height: 44px !important;
  }

  .card-subheader {
    font-size: 20px !important;
    line-height: 27px !important;
  }

  .google-btn {
    border-radius: 10px;
    height: 43.278px;
    padding: 5px;
  }

  .facebook-btn {
    border-radius: 10px;
    height: 43.278px;
    padding: 5px;
  }

  .line {
    width: 100%;
    height: 0px;
    border: 1.5px solid rgba(0, 0, 0, 0.25);
    margin: 0;
  }

  .divider-text {
    font-size: 16px;
    line-height: 19px;
    margin: 10px 0;
  }

  .forgot-password-btn {
    font-size: 11px;
    line-height: 13px;
  }

  .login-help-btn {
    font-size: 11px;
    line-height: 13px;
  }

  .submit-btn {
    font-size: 24px;
    line-height: 33px;
    border-radius: 7px;
    height: 43px;
    margin-top: 1em;
  }

  .submit-btn-2 {
    font-size: 24px;
    line-height: 33px;
    border-radius: 7px;
    height: 43px;
    margin-top: 1em;
  }

  .secondary-btn {
    font-size: 24px;
    line-height: 33px;
    border-radius: 7px;
    height: 43px;
    margin-top: 0.5em;
    padding: 0;
  }

  .form-btn {
    font-size: 20px;
    line-height: 22px;
    border-radius: 10px;
  }

  .login-return {
    margin-bottom: 10px;
  }

  .small-text {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
  }
}

@media (max-width: 600px) {
  .authentication-page {
    background-image: url("../../assets/images/authentication-background-mobile.png");
  }

  .authentication-card {
    border-radius: 10px;
    /* min-height: 328px !important; */
    height: fit-content !important;
    width: 343px !important;
    max-width: fit-content;
    padding: 1em 1em;
    box-shadow: 0px 4px 35px 10px rgba(0, 0, 0, 0.25);
  }

  .app-logo {
    height: fit-content;
    max-width: 343px;
    margin: 20px auto;
  }

  .inside {
    display: none;
  }

  .outside {
    display: block;
  }

  .card-header {
    font-size: 20px !important;
    line-height: 27px !important;
  }

  .card-subheader {
    font-size: 14px !important;
    line-height: 19px !important;
  }

  .google-btn {
    border-radius: 5px;
    height: 28px;
    padding: 2px;
  }

  .facebook-btn {
    border-radius: 5px;
    height: 28px;
    padding: 2px;
  }

  .line {
    width: 100%;
    height: 0px;
    border: 1.5px solid rgba(0, 0, 0, 0.25);
    margin: 0;
  }

  .divider-text {
    font-size: 15px;
    line-height: 18px;
    margin: 4px 0;
  }

  .forgot-password-btn {
    font-size: 10px;
    line-height: 12px;
  }

  .login-help-btn {
    font-size: 10px;
    line-height: 12px;
  }

  .submit-btn {
    font-size: 20px;
    line-height: 27px;
    border-radius: 7px;
    height: 37px;
    margin-top: 0.5em;
  }

  .submit-btn-2 {
    font-size: 20px;
    line-height: 27px;
    border-radius: 7px;
    height: 37px;
    margin-top: 0.5em;
  }

  .secondary-btn {
    font-size: 20px;
    line-height: 27px;
    border-radius: 7px;
    height: 37px;
    margin-top: 0.5em;
    padding: 0;
  }

  .form-btn {
    font-size: 15px;
    line-height: 18px;
    border-radius: 5px;
  }

  .login-return {
    margin-bottom: 5px;
  }

  .small-text {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
  }
}
