.form {
  color: #399b96;
  font-size: 20px;
  font-weight: 600;
}

.m-2 {
  margin-top: 10px;
}
.mr-2 {
  margin-right: 10px;
}

.category-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll; */
}

.hr-blue {
  margin: 5px 10px 20px 10px;
  height: 3px;
  border-radius: 2px;
  background-color: #b5d3ff;
}

.category_name {
  margin-bottom: 10%;
  color: #2e72d2;
}
.input_one textarea {
  width: 100%;
  height: 6.5em;
  border: 2px solid #b5d3ff;
  border-radius: 3px;
  resize: vertical;
  min-height: 2em;
  max-height: 12em;
}

.input_one textarea:focus-visible {
  outline: none;
}

.input_one h5,
.input_two h5,
.input_three h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: black;
}

.input_one {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
}

.input_two textarea,
.input_three textarea {
  width: 100%;
  height: 102px;
  border: 2px solid #e0e4ec;
  border-radius: 3px;
}
.input_one,
.input_two,
.input_three {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
  text-align: left;
}
.submitButton button {
  font-family: "Manrope";
  font-style: normal;
  font-weight: "Bold";
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
  text-align: center;
  color: #154b89;
  background: #ffffff;
  border: 4px solid #b5d3ff;
  border-radius: 7px;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin: 0 auto;
}
.submitButton button:hover:enabled {
  font-family: "Manrope";
  font-style: normal;
  font-weight: "Bold";
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #154b89;
  border: 7px solid #154b89;
  border-radius: 7px;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border: none;
}
.submitButton button:disabled {
  filter: opacity(1);
  filter: grayscale(1);
  cursor: not-allowed;
}

.main-division {
  display: flex;
  flex-grow: 1;
  /* cursor: pointer; */
}
label {
  cursor: pointer !important;
}
.output-container {
  height: 75vh;
  overflow-y: scroll;
  margin: 0 auto;
}

@media (max-width: 900px) {
  .output-container {
    overflow-y: visible;
    height: fit-content;
    padding-bottom: 10px;
  }
}

.output-layout {
  border-radius: 8px;
  height: fit-content;
  margin: 14px;
  background: #b5d3ff;
  color: white;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
}
.ot-hd {
  font-size: 20px;
  height: 100%;
  background: #b5d3ff;
  font-weight: 600;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ot-bd {
  font-size: 18px;
  color: black;
  font-weight: 600;
  font-family: Manrope;
  padding: 10px;
  white-space: pre-wrap;
}

.cpy-btn {
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  background: #b5d3ff;
  border: 2px solid #154b89;
  border-radius: 5px;
  color: #154b89;
  width: 100%;
  height: 34px;
  font-weight: Bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 5px;
}

.cpy-btn:hover {
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 5px;
  background: #154b89;
  color: #fff;
  width: 100%;
  height: 34px;
}

.cpyall-btn {
  background: #ffff;
  border: 2px solid #154b89;
  border-radius: 7px;
  color: #154b89;
  font-weight: 600;
  font-family: serif;
  text-transform: uppercase;
  margin: 10px auto 0 auto;
  letter-spacing: 1px;
}

.cpyall-btn:hover {
  margin: 10px auto 0 auto;
  background: #154b89;
  color: #fff;
}
.hd-cp {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
h1.text-status {
  position: absolute;
  background: #154b89;
  font-size: 25px;
  padding: 6px;
  width: max-content;
  right: 60px;
  top: 0px;
  border-radius: 4px;
}
h1.text-status-save {
  position: absolute;
  background: #154b89;
  font-size: 25px;
  padding: 6px;
  width: max-content;
  /* right: 60px; */
  top: 0px;
  border-radius: 4px;
}
.text-cp-al {
  background: #154b89;
  font-size: 22px;
  font-weight: 600;
  padding: 6px;
  width: max-content;
  border-radius: 4px;
  margin: auto;
  margin-top: 10px;
  color: #fff;
}

.hd-logo {
  display: flex;
  justify-content: inherit;
  padding: 10px;
  margin: 0 20px;
}
.hd-logo img {
  display: flex;
  width: 100%;
  height: auto;
  /* margin: 20px 0px 0px 20px; */
}
.lg-title {
  text-align: center;
}

.tone-btn {
  border: 2px solid #b5d3ff !important;
  width: 100% !important;
  text-transform: capitalize !important;
  height: 100%;
  font-weight: 800 !important;
}
.tone-btn-selected {
  background-color: #154b89 !important;
  color: #fff !important;
  width: 100% !important;
  text-transform: capitalize !important;
  height: 100%;
  font-weight: 800 !important;
}

.tone-btn:hover {
  background-color: #154b89 !important;
  color: #fff;
  width: 100% !important;
  text-transform: capitalize;
}

.home-btn {
  text-align: center;
  text-decoration: none;
  border: 1px solid #000;
  border-radius: 8px;
  background: #154b89;
  color: #fff !important;
  font-weight: 600 !important;
  margin: 5px 0;
  padding: 8px;
  width: 100%;
}

.home-btn:hover {
  border: 1px solid #b5d3ff;
  background: #0f3764;
  color: #fff !important;
}

.err-msg {
  display: flex;
  font-size: 26px !important;
  justify-content: center;
  border: 2px solid #e15e28;
  margin: 10px;
  font-weight: 700;
  color: #e15e28;
}

strong {
  color: black;
}
.side-Bar {
  display: flex;
  padding: 20px;
  flex-direction: column;
}

.side-Bar .dropdown {
  display: contents;
}

.dropdown-menu.show {
  display: block;
  width: 100%;
  text-align: center;
}
.btn-secondary::after {
  display: none;
}

.dropdown-toggle::after {
  display: none !important;
}

.arrow-img {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
/* [area-expanded="true"] img {
  transform: rotate (180);
} */
button[aria-expanded="true"] {
  background-color: #154b89 !important;
  color: #ffffff;
}
button[aria-expanded="true"] img {
  transition: ease 0.7s;
  transform: rotate(180deg);
}
button[aria-expanded="false"] img {
  transition: ease 0.7s;
  transform: rotate(0deg);
}
.category-title {
  display: flex;
  margin: 0;
  background: rgba(181, 211, 255, 0.9);
  color: #2e72d2;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 0;
}

.category-title h2 {
  margin: 0;
}

.smooth-edge {
  border-radius: 5px;
}

.category-title h2 {
  font-weight: 800;
  font-size: 32px;
  line-height: 44px;
  color: black;
}

.result-title {
  text-align: center;
  margin: 10px 0;
  font-size: 20px;
}

.result-card-title-bar {
  height: 40px;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
}

/*Media Query For responsive Design*/

@media only screen and (max-width: 1000px) {
  .main-form-desktop-view {
    display: none;
  }
  .category-title-text {
    display: none !important;
  }
  .header-mobile-view {
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: rgba(181, 211, 255, 0.9);
    /* position: absolute;
    z-index: 1; */
  }
  .hamburger-menu {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .main-division {
    display: none !important;
  }
  .mobile-view-product {
    overflow-y: auto;
  }
  .mobile-view-product ul[aria-expanded="true"] {
    display: block;
    background-color: #f7f7f7;
    position: absolute;
    height: 100%;
    animation: slide-from-left 0.3s forwards;
    z-index: 1;
    width: calc(100vw - 10px);
    padding: 10px;
  }

  /* TranslateX is used instead of left:0 since translateX
     performs better on mobile devices */
  @keyframes slide-from-left {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  .mobile-view-product ul[aria-expanded="false"] {
    display: none;
  }
  .main-form-mobile {
    padding: 8px;
  }
  .main-form-mobile p {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mobile-category-title {
    font-size: 30px;
    font-weight: 800;
    background-color: rgba(181, 211, 255, 0.9);
  }
  .result-mobile-view {
    padding: 10px;
  }
  .output-container-mobile-view {
    width: 100%;
    position: relative;
  }
  .output-layout-mobile-view {
    position: absolute;
  }
  .mobile-view-carousal-card {
    width: 100%;
    background-color: #b5d3ff;
    box-shadow: 3px 4px 7px 1px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    margin-top: 3%;
    overflow-y: hidden;
  }
  .mobile-view-carousal-card h4 {
    font-weight: 600;
    white-space: pre-wrap;
  }
  .output-container-mobile-view div[aria-expanded="true"] {
    transition: ease 1s;
  }
  .output-container-mobile-view div[aria-expanded="false"] {
    transition: ease 1s;
  }
  .mobile-carousal-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 15px;
  }
  .mobile-carousal-buttons button {
    border: none;
    background-color: transparent;
  }
  .mobile-carousal-card-title {
    width: 100%;
    display: flex;
    min-height: 40px;
    height: fit-content;
    margin-bottom: 2%;
    justify-content: center;
    align-items: center;
    /* margin-top: -15px;
    margin-left: -15px; */
    border-radius: 8px 8px 0px 0px;
    background-color: #a9cbff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }
  .mobile-carousal-card-title h3 {
    font-weight: 800;
  }
  .mobile-left-arrow {
    width: 15px;
  }
  .mobile-right-arrow img {
    transform: rotate(180deg);
  }
  .mobile-carousal-card-title button {
    background-color: #154b89;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    text-align: center;
    padding: 5px;
  }
  .cpy-btn-mobile-view {
    right: 0;
  }
  .hamburger-menu svg {
    width: 1.3em !important;
    height: 1.3em !important;
  }

  .result-body {
    margin: 0;
    padding: 0 15px;
    padding-bottom: 15px;
  }

  .result-body h4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    height: fit-content;
    max-height: 30vh;
    overflow-y: auto;
  }

  .tone-btn {
    border-radius: 5px !important;
  }

  .tone-btn-selected {
    border-radius: 5px !important;
  }

  .input_one textarea {
    border-radius: 5px;
  }

  .input_two textarea {
    border-radius: 5px;
  }

  .input_three {
    margin-bottom: 5%;
  }
}

@media only screen and (max-width: 2000px) and (min-width: 1000px) {
  .header-mobile-view {
    display: none;
  }
  .mobile-view-product {
    display: none;
  }

  .input_one textarea {
    border-radius: 5px;
  }

  .input_two textarea {
    border-radius: 5px;
  }
}

@media only screen and (max-width: 600px) {
  .header-mobile-view {
    width: 100%;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: rgba(181, 211, 255, 0.9);
  }
  .hamburger-menu {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
