html {
  height: 100%;
}
body {
  height: 100%;
}

div#root {
  height: 100%;
}

.App {
  font-family: "Manrope";
  /* height: 100%; */
}
.App > div {
  height: 100%;
}

.logo-panel {
  position: relative;
  background-image: url("../src/assets/images/Sidebar-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0px 4px 5px 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/*h2 {
  margin-left: -5%;
  color: #2e72d2;
  margin-top: 5% !important;
  font-family: "Manrope";
  
}*/

.buttons-panel {
  font-size: 36px;
  margin-left: 10%;
}

.category-title-wrapper {
  margin: 0 auto;
  width: 90%;
}

.category-title {
  display: flex;
  margin-bottom: 15px;
  background: rgba(181, 211, 255, 0.9);
  color: #2e72d2;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
}

.side-bar-menu {
  height: 100%;
  overflow: hidden;
}

.static-menu-items {
  padding: 0 20px 20px 20px;
}

.scrollable-menu-items {
  position: inherit;
  height: 50vh;
  overflow-y: scroll;
  padding: 0 20px 20px 20px;
}

.scrollable-menu-items::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

@media (max-height: 1000px) {
  .scrollable-menu-items {
    height: 60vh;
  }
}

@media (max-height: 830px) {
  .scrollable-menu-items {
    height: 45vh;
  }
}

@media (max-height: 600px) {
  .scrollable-menu-items {
    height: 30vh;
  }
}

.menu-separator {
  border: none;
  margin: 0;
  opacity: 1;
  height: 2px;
  background-color: black;
  animation: fade-in 0.7s ease both;
}

.accordion {
  margin-top: 10px;
  background-color: rgba(181, 211, 255, 0.9) !important;
  color: black !important;
  font-size: 1.2vw !important;
  font-weight: 600 !important;
  border-radius: 5px !important;
}

.accordion-header {
  min-height: 32px !important;
  padding: 0 10px !important;
}

.accordion.Mui-expanded {
  margin: 10px 0 !important;
  animation: background-fade 0.7s ease forwards;
}

.accordion-header.Mui-expanded {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  animation: background-fade 0.7s ease forwards;
}

.MuiAccordionSummary-content {
  margin: 0px !important;
}

.accordion-header.Mui-expanded + .MuiCollapse-root {
  animation: background-fade 0.7s ease forwards;
}

.accordion-item {
  font-size: 1.2vw;
  font-weight: 600;
  background-color: rgba(181, 211, 255, 0.9) !important;
  text-align: center;
  margin: 10px 0 10px 0;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 50%);
  border-radius: 5px;
  border: 0px !important;
}

.accordion-item:hover {
  background-color: #154b89 !important;
}

@keyframes background-fade {
  0% {
    background-color: rgba(181, 211, 255, 0.9);
  }
  100% {
    background-color: rgba(140, 185, 251, 0.9);
  }
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.splitScreen {
  display: "flex";
  flex-direction: "row";
}

.topPane {
  width: "50%";
}

.bottomPane {
  width: "50%";
}

footer {
  position: absolute;
  width: 100%;
  display: flex;
  background-color: rgb(144 176 224);
  height: 6vh;
  min-height: fit-content;
  text-align: center;
  align-items: center;
  font-weight: bold;
  font-family: "Manrope";
}

.infobutton {
  width: 90%;
  height: 50px;
  color: #ffffff;
  font-weight: bold;
  font-size: 24px;
  background: #2c5d95;
  border: 7px solid #2c5d95;
}

.infobutton:hover {
  transform: scale(1.05);
  transition-duration: 0.5s;
  background: #154b89;
  border: 7px solid #154b89;
}

.skipbutton {
  width: 90%;
  height: 50px;
  color: #154b89;
  font-weight: bold;
  font-size: 24px;
  background: #ffffff;
  border: 7px solid #154b89;
  border-radius: 7px;
}

.skipbutton:hover {
  transform: scale(1.05);
  transition-duration: 0.5s;
  color: #ffffff;
  background: #154b89;
  cursor: pointer;
}

.category-link {
  display: block;
  color: black;
  text-decoration: none;
  min-width: 100%;
  padding: 3px;
}

.category-link:hover {
  color: white;
}

.icon-component {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.icon-component p {
  margin: 0 0 0 5px;
}

/*
style={{ position: "absolute", width: "229px", height: "45px", color: "#154B89", fontWeight: "bold", fontSize: "24px", border: "7px solid #154B89"}}


style={{ position: "absolute", width: "229px", height: "45px", backgroundColor: "#2C5D95", color: "white", fontWeight: "bold", fontSize: "24px", marginLeft: "-1.5%"}}

.infobutton:before {
  position: "absolute";
  width: 229px;
  height: 45px;
  background-color: #000;
  font-weight: bold;
  font-size: 24px;
  margin-top: "1.3%";
  border-radius: 10;
}


.infobutton:hover {
  position: "absolute";
  width: 229px;
  height: 45px;
  background-color: #154B89;
  border: 7px solid;
  border-color: #154B89;
  color: #ffffff;
  font-weight: bold;
  font-size: 24px;
  margin-top: "1.3%";
  border-radius: 10;
}


.skipbutton {
  position: absolute;
  width: 229px;
  height: 45px;
  color: #154B89;
  font-weight: bold;
  font-size: 24px;
  border: 7px solid;
  border-color: #154B89;
}



.button {
  background-color: rgb(128, 11, 11);
  color: white;
  font-size: 20px;
  padding: 10px 60px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
}

.infobutton {
  position: absolute;
  width: 229px;
  height: 50px;
  background-color: rgba(21, 75, 137, 0.9);
  border-radius: 7px;
}*/

/*Media Query*/
@media only screen and (max-width: 1000px) {
  .scrollable-menu-items {
    display: none !important;
  }
  .logo-panel {
    display: none !important;
  }
  footer {
    display: none !important;
  }
  .hamburger-menu {
    display: block;
  }
}
